import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.js";
import XA from '../components/ExternalAnchor';
import ZoomImage from '../components/ZoomImage';
import blue from '../img/blue.jpg';
import blueZoom from '../img/blue-zoom.jpg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <div className="blog-section">
      <h2 {...{
        "id": "howdy-🤠",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#howdy-%F0%9F%A4%A0",
          "aria-label": "howdy 🤠 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Howdy 🤠`}</h2>
      <p>{`My name is Preston and I’m a software engineer living in Brooklyn. `}</p>
      <p>{`I work at `}<XA href="https://observablehq.com/" mdxType="XA">{`Observable`}</XA>{`!`}</p>
      <p>{`Here’s a picture of my unbelievably cute cat, Blue (who you can follow on Instagram `}<XA href="https://www.instagram.com/blue.the.lynxpoint/" mdxType="XA">{`here`}</XA>{`):`}</p>
      <div className="blog-inset">
  <ZoomImage src={blue} zoomSrc={blueZoom} mdxType="ZoomImage" />
      </div>
      <p>{`I don’t post much on social media, but you can find me on `}<XA href="https://www.flickr.com/photos/prestonrichey" mdxType="XA">{`Flickr`}</XA>{`, `}<XA href="https://github.com/prichey" mdxType="XA">{`Github`}</XA>{`, and `}<XA href="https://www.strava.com/athletes/6928920" mdxType="XA">{`Strava`}</XA>{`, or reach me via `}<XA href="mailto:prestonleerichey@gmail.com" mdxType="XA">{`email`}</XA>{`.`}</p>
    </div>
    <div className="blog-section">
      <h2 {...{
        "id": "colophon",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#colophon",
          "aria-label": "colophon permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Colophon`}</h2>
      <p>{`This site was made with the excellent `}<XA href="https://www.gatsbyjs.org/" mdxType="XA">{`Gatsby.js`}</XA>{` and is hosted on `}<XA href="https://www.netlify.com/" mdxType="XA">{`Netlify`}</XA>{`. You can look at its code `}<XA href="https://github.com/prichey/prestonrichey.com" mdxType="XA">{`here`}</XA>{`. I used `}<XA href="https://threejs.org/" mdxType="XA">{`three.js`}</XA>{` to import a model of my head which was scanned with a `}<XA href="https://structure.io/" mdxType="XA">{`Structure Sensor`}</XA>{`.`}</p>
      <p>{`The monospace font is `}<XA href="https://fonts.google.com/specimen/Roboto+Mono" mdxType="XA">{`Roboto Mono`}</XA>{` and the sans-serif is `}<XA href="https://fonts.google.com/specimen/Rubik" mdxType="XA">{`Rubik`}</XA>{`.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      